import React from 'react';
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./store/configStore";
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.scss';

const container = document.getElementById('root');
if (container == null) throw new Error("root container is missing in index.html")

const root = ReactDOM.createRoot(container)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();