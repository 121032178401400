import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelectedKpiOption {
    label: string;
    value: {
        name: string,
        isSelected: boolean
    }[];
}

export interface SelectedCohortOption {
    label: string;
    value: {
        name: string,
        demoValue: number,
        isSelected: boolean
    }[];
}

export interface KpiState {
    analysisId: string;
    kpis: SelectedKpiOption[];
    cohort: SelectedCohortOption[];
    statusCondition: number;
}

const initialState: KpiState = {
    analysisId: "",
    kpis: [],
    cohort: [], // Initialize cohort as an empty array
    statusCondition: 0
};

export const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        selectedKpi: (state, action: PayloadAction<{ kpis: SelectedKpiOption[] }>) => {
            const { kpis } = action.payload;
            state.kpis = kpis;
        },
        selectedCohort: (state, action: PayloadAction<{ cohort: SelectedCohortOption[] }>) => {
            const { cohort } = action.payload;
            state.cohort = cohort;
        },
        selectAnalysisId: (state, action: PayloadAction<{ analysisId: string }>) => {
            const { analysisId } = action.payload;
            state.analysisId = analysisId;
            state.cohort = [];
            state.kpis = [];
        },
        statusCondition: (state, action: PayloadAction<{ statusCondition: number }>) => {
            const { statusCondition } = action.payload;
            state.statusCondition = statusCondition;
        },
    },
});

export const { selectedKpi, selectedCohort, selectAnalysisId, statusCondition } = analysisSlice.actions;

export default analysisSlice.reducer;
