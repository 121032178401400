import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

interface StateInterface {
    reload: boolean;
    toastmsg: TostMsg[];
    notifications: Record<string, any>;
    isDark?: boolean;
    title: string;
    isOverall?: boolean;
}

const initialState: StateInterface = {
    reload: false,
    toastmsg: [],
    title: "",
    notifications: { modifyAnalysisChannel: [], analysisChannel: [] },
    isDark: false,
    isOverall: false
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        reload: (state) => {
            state.reload = !state.reload;
        },
        tostMsg: (state, action: PayloadAction<Omit<TostMsg, "id">>) => {
            let isPresent = false;
            //TODO : add analysis ID or dashbaordID check
            state.toastmsg.forEach((item) => {
                if (item.msg === action.payload.msg) {
                    isPresent = true;
                }
            })
            if (!isPresent) {
                const newObj = { ...action.payload, id: uuidv4() };
                state.toastmsg = [...state.toastmsg, newObj];
            }
        },
        removeTost: (state, action: PayloadAction<string>) => {
            const leftNotify = state.toastmsg.filter((ele) => ele.id !== action.payload);
            state.toastmsg = leftNotify;
        },
        addNotification: (state, action) => {
            state.notifications[action.payload.channel].push(action.payload.data);
        },
        removeNotification: (state, action) => {
            if (action.payload.channel === 'analysisChannel')
                state.notifications[action.payload.channel] = state.notifications[action.payload.channel].filter((ele: any) => ele.dashboardId != action.payload.data);
            else
                state.notifications[action.payload.channel] = state.notifications[action.payload.channel].filter((ele: any) => ele.widgetID != action.payload.data);
        },
        addTitle: (state, action) => {
            state.title = action.payload.title;
        },
        setOverall: (state, action) => {
            state.isOverall = action.payload.setOverall
        }
    },
});

export const { reload, tostMsg, removeTost, addNotification, removeNotification, addTitle, setOverall } = globalSlice.actions;

export default globalSlice.reducer;