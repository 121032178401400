import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DIATlayouts, policyLayouts, highTouchLayouts, lowTouchLayouts, ONALayouts, OverallCulturalLayouts, OverallDIATlayouts, TALayouts } from "./../../constants"


interface Analysis {
    analysisName: string
    analysisType: string
    analysisID: string
    widgetData: Record<string, string>[]
}

interface OverallWidgets {
    analysisName: string
    analysisType: string
    analysisID: string
    widgetData: Record<string, string>[]
}
interface textEditedWidgetstype {
    id: string
    text: string
}
export interface DashboardState {
    analysisList: Analysis[] | undefined,
    deletedWidgets: string[],
    value: number,
    publishUrl: string | undefined,
    DIATlayout: Record<string, any[]>
    policyLayout: Record<string, any[]>
    highTouchLayout: Record<string, any[]>
    lowTouchLayout: Record<string, any[]>
    ONALayout: Record<string, any[]>
    OverallCulturalLayout: Record<string, any[]>
    OverallDIATlayout: Record<string, any[]>
    TALayout: Record<string, any>
    overallWidgetList: OverallWidgets[] | undefined
    count: number[],
    textEditedWidgets: textEditedWidgetstype[];
}

const initialState: DashboardState = {
    analysisList: undefined,
    deletedWidgets: [],
    value: 0,
    publishUrl: undefined,
    DIATlayout: DIATlayouts,
    policyLayout: policyLayouts,
    highTouchLayout: highTouchLayouts,
    lowTouchLayout: lowTouchLayouts,
    ONALayout: ONALayouts,
    OverallCulturalLayout: OverallCulturalLayouts,
    OverallDIATlayout: OverallDIATlayouts,
    TALayout: TALayouts,
    overallWidgetList: undefined,
    count: [],
    textEditedWidgets: []
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        addToDeleteList: (state, action: PayloadAction<{ id: string }>) => {
            state.deletedWidgets.push(action.payload.id)
        },
        setAnalysisList: (state, action: PayloadAction<any>) => {
            if (action.payload) {
                state.analysisList = JSON.parse(JSON.stringify(action.payload))
            }
            state.deletedWidgets = []
        },
        setOverallWidgetList: (state, action: PayloadAction<any>) => {
            if (action.payload) {
                state.overallWidgetList = JSON.parse(JSON.stringify(action.payload))
            }
            state.deletedWidgets = []
        },
        removeWidget: (state, action: PayloadAction<{ id: string }>) => {
            state.analysisList = state.analysisList?.map((analysis) => ({
                ...analysis,
                widgetData: analysis.widgetData.filter((widget: any) => widget.id !== action.payload.id)
            }))
            state.deletedWidgets.push(action.payload.id)
        },
        removeOverallWidgetList: (state, action: PayloadAction<{ id: string }>) => {
            state.analysisList = state.analysisList?.filter((widget: any) => widget.id !== action.payload.id)
            state.deletedWidgets.push(action.payload.id)
        },
        updateDiatLayout: (state, action: PayloadAction<any>) => {
            state.DIATlayout = JSON.parse(JSON.stringify(action.payload))
        },
        updatePolicyLayout: (state, action: PayloadAction<any>) => {
            state.policyLayout = JSON.parse(JSON.stringify(action.payload))
        },
        updateHighTouchLayout: (state, action: PayloadAction<any>) => {
            state.highTouchLayout = JSON.parse(JSON.stringify(action.payload))
        },
        updateONALayout: (state, action: PayloadAction<any>) => {
            state.ONALayout = JSON.parse(JSON.stringify(action.payload))
        },
        updateLowTouchLayout: (state, action: PayloadAction<any>) => {
            state.lowTouchLayout = JSON.parse(JSON.stringify(action.payload))
        },
        updateTALayout: (state, action: PayloadAction<{ selectedOpt: string, layout: any }>) => {
            state.TALayout = {
                ...state.TALayout,
                [action.payload.selectedOpt]: JSON.parse(JSON.stringify(action.payload.layout))
            }
        },
        updateAllLayout: (state, action: PayloadAction<any>) => {
            state.DIATlayout = action.payload?.DIAT || state.DIATlayout
        },
        updatePublishUrl: (state, action) => {
            state.publishUrl = action.payload.url
        },
        updateOverallCulturalLayout: (state, action: PayloadAction<any>) => {
            state.OverallCulturalLayout = JSON.parse(JSON.stringify(action.payload))
        },
        updateOverallDIATlayout: (state, action: PayloadAction<any>) => {
            state.OverallDIATlayout = JSON.parse(JSON.stringify(action.payload))
        },
        totalNotification: (state, action: PayloadAction<{ count: number[] }>) => {
            const { count } = action.payload;
            state.count = count;
        },
        editWidgetTitle: (state, action: PayloadAction<{ widgetId: string, newTitle: string }>) => {
            state.textEditedWidgets = state.textEditedWidgets?.filter((updatedWidget) => updatedWidget.id !== action.payload.widgetId);
            state.textEditedWidgets = [...state.textEditedWidgets, { id: action.payload.widgetId, text: action.payload.newTitle }]
        }
    },
})

export const { updateDiatLayout, updateONALayout, updatePolicyLayout, updateAllLayout, updateHighTouchLayout, updateLowTouchLayout, updatePublishUrl, removeWidget, removeOverallWidgetList, setAnalysisList, setOverallWidgetList, updateOverallCulturalLayout, updateOverallDIATlayout, updateTALayout, totalNotification, editWidgetTitle } = dashboardSlice.actions

export default dashboardSlice.reducer
