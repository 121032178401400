import { RouterProvider } from "react-router-dom";
import router from "./router";
import { SocketClient } from "./socket";


export const socketClientInstance = SocketClient.getSocketInstance();

const App = () => {
    return (
        <div className="main-app">
            <RouterProvider router={router} />
        </div>
    );
};

export default App;