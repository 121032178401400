import { createApi, fetchBaseQuery, BaseQueryApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "./configStore";
import { tostMsg } from "../global/store/slice";
import { triggerLogout, setSessionPopup } from '../loginPage/store/slice';

const sessionErrors = ["session expired", "session does not exist", "unauthorized http access"];


const baseQuery = fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
        const token = sessionStorage.getItem('accessToken');
        const state = getState() as RootState;
        const csrfToken = state.csrf.token;
        if (token) {
            headers.set("authorization", token);
        }
        if (csrfToken) {
            headers.set('X-CSRF-Token', csrfToken);
        }
        return headers;
    }
});

const baseQueryWithReAuth = async (args: any, api: BaseQueryApi, extraOption: any) => {
    let res: any = await baseQuery(args, api, extraOption);
    if (res.error) {
        let error = res.error.data;
        let errorMessage = error?.message || error?.msg || "Internal server error";

        if (sessionErrors.includes(errorMessage.toLowerCase()) || res?.error?.status === 440) {

            const refreshToken = sessionStorage.getItem("refreshToken")

            const refreshRes: any = await baseQuery({
                url: "surveyAdmin/api/v1.0/userauth/fetchRefreshedAccessToken",
                method: "POST",
                body: {
                    refreshToken: refreshToken
                }
            }, api, extraOption);

            if (refreshRes?.data) {
                sessionStorage.setItem("accessToken", refreshRes?.data?.data?.accessToken)
                sessionStorage.setItem("idToken", refreshRes?.data?.data?.idToken)
                res = await baseQuery(args, api, extraOption);
            } else {
                // sessionStorage.setItem("sessionError", error.response?.data?.message?.toLowerCase())
                api.dispatch(setSessionPopup(false));
                api.dispatch(tostMsg({
                    type: "ERROR", msg: (errorMessage)
                }));
                api.dispatch(triggerLogout({}));
            }
        }
    }
    return res
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({})
});