import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

const LoginPageContainer = React.lazy(() => import("./loginPage"));
const AnalysingData = React.lazy(() => import("./analysis/AnalysingData"));
const CreateAnalysisPage = React.lazy(() => import("./analysis/CreateAnalysis"));
const PoliciesUploadPage = React.lazy(() => import("./analysis/components/PoliciesUploadPage"));
const DiatUploadPage = React.lazy(() => import("./analysis/components/DiatUploadPage"));
const OnaUploadPage = React.lazy(() => import("./analysis/components/OnaUploadPage"));
const CulturalInwardUploadPage = React.lazy(() => import("./analysis/components/CulturalInwardUploadPage"));
const CulturalOutwardUploadPage = React.lazy(() => import("./analysis/components/CulturalOutwardUploadPage"));
const AnalysisMainPage = React.lazy(() => import("./analysis/AnalysisMainPage"));
const TalentUploadPage = React.lazy(() => import("./analysis/components/TalentAnalysis/TalentUploadPage"));
const KpiSelectPage = React.lazy(() => import('./analysis/components/TalentAnalysis/KpiSelectPage'));
const CohortSelectPage = React.lazy(() => import('./analysis/components/TalentAnalysis/CohortSelectPage'));
const HomePage = React.lazy(() => import('./homePage/HomePage'));
const DashboardLandingPage = React.lazy(() => import('./Dashboard/LandingPage'));
const CreateDashboard = React.lazy(() => import('./Dashboard/CreateDashboard'));
const CreateOverallDashboard = React.lazy(() => import('./Dashboard/CreateOverallDashboard'));
const CulturalOverallDashboard = React.lazy(() => import('./Dashboard/CreateOverallDashboard/CulturalOverallDashboard'));
const PotentialOverallDashboard = React.lazy(() => import('./Dashboard/CreateOverallDashboard/PotentialOverallDashboard'));
const MyDashboard = React.lazy(() => import('./Dashboard/MyDashboard'));
const CulturalAnalysisPage = React.lazy(() => import('./analysis/components/CulturalAnalysis'));
const UnconsciousAnalysisPage = React.lazy(() => import('./analysis/components/UnconsciousAnalysis'));
const PublishDashboard = React.lazy(() => import('./publish/PublishDashboard'));
const PolicyReport = React.lazy(() => import('./analysisResult/policy/Report'));
const PolicyDetails = React.lazy(() => import('./analysisResult/policy/Details'));
const Page404 = React.lazy(() => import('./global/components/pageNotFound/Page404'));
const Layout = React.lazy(() => import('./Layout'))

const SuspenseWrapper = ({ children }: any) => {

    return <Suspense fallback={<></>}>{children}</Suspense>
}
const router = createBrowserRouter([
    {
        path: "/",
        element: <SuspenseWrapper><Layout /></SuspenseWrapper>,
        children: [
            {
                path: "index.html",
                element: <Layout />,
            },
            {
                path: "",
                element: <SuspenseWrapper><HomePage /></SuspenseWrapper>
            },
            {
                path: "home",
                element: <SuspenseWrapper><HomePage /></SuspenseWrapper>
            },

            {
                path: "dashboard",
                children: [
                    {
                        path: "",
                        element: <SuspenseWrapper> <DashboardLandingPage /></SuspenseWrapper>
                    },
                    {
                        path: "create",
                        children: [
                            {
                                path: "newdashboard",
                                element: <SuspenseWrapper><CreateDashboard /></SuspenseWrapper>,

                            },
                            {
                                path: "newoveralldashboard",
                                element: <SuspenseWrapper><CreateOverallDashboard /></SuspenseWrapper>,
                            }
                        ]

                    },
                    {
                        path: "create/:id",
                        element: <SuspenseWrapper><CreateDashboard /></SuspenseWrapper>,

                    },
                    {
                        path: "create-culture-blank",
                        element: <SuspenseWrapper><CulturalOverallDashboard /></SuspenseWrapper>
                    },
                    {
                        path: "create-potential-blank",
                        element: <SuspenseWrapper><PotentialOverallDashboard /></SuspenseWrapper>
                    },
                    {
                        path: ":id",
                        element: <SuspenseWrapper><MyDashboard published={undefined} /></SuspenseWrapper>,
                    },
                    {
                        path: ":id/report/:reportID",
                        element: <SuspenseWrapper><PolicyReport published={false} /></SuspenseWrapper>
                    },
                    {
                        path: ":id/report/:reportID/:analysisID/:detailedBiasUUID",
                        element: <SuspenseWrapper><PolicyDetails published={false} /></SuspenseWrapper>
                    }
                ]
            },
            {
                path: "analysis",
                children: [
                    {
                        path: "",
                        element: <SuspenseWrapper><AnalysisMainPage /></SuspenseWrapper>,
                    },
                    {
                        path: "create",
                        children: [
                            {
                                path: "cultural",
                                element: <SuspenseWrapper> <CulturalAnalysisPage /></SuspenseWrapper>
                            },
                            {
                                path: "potentialunconsciousbias",
                                element: <SuspenseWrapper><UnconsciousAnalysisPage /></SuspenseWrapper>
                            },
                            {
                                path: "",
                                element: <SuspenseWrapper><CreateAnalysisPage /></SuspenseWrapper>
                            }
                        ]
                    },
                    {
                        path: "uploaddiat/:id",
                        element: <SuspenseWrapper><DiatUploadPage /></SuspenseWrapper>
                    },
                    {
                        path: "uploadpolicies/:id",
                        element: <SuspenseWrapper><PoliciesUploadPage /></SuspenseWrapper>
                    },
                    {
                        path: "uploadona/:id",
                        element: <SuspenseWrapper><OnaUploadPage /></SuspenseWrapper>
                    },
                    {
                        path: "uploadculturalinward/:id",
                        element: <SuspenseWrapper><CulturalInwardUploadPage /></SuspenseWrapper>
                    },
                    {
                        path: "uploadculturaloutward/:id",
                        element: <SuspenseWrapper><CulturalOutwardUploadPage /></SuspenseWrapper>
                    },
                    {
                        path: "uploadtalent",
                        children: [
                            {
                                path: ":id",
                                element: <SuspenseWrapper><TalentUploadPage /></SuspenseWrapper>
                            },
                            {
                                path: "selectkpi",
                                children: [
                                    {
                                        path: ":id",
                                        element: <SuspenseWrapper><KpiSelectPage /></SuspenseWrapper>
                                    },
                                    {
                                        path: "selectcohort/:id",
                                        element: <SuspenseWrapper><CohortSelectPage /></SuspenseWrapper>
                                    }
                                ]
                            }

                        ]

                    },
                    {
                        path: "analysing/:id",
                        element: <SuspenseWrapper><AnalysingData /></SuspenseWrapper>
                    }
                ]
            },
            {
                path: "publishData/fetchDashboard/:id",
                element: <SuspenseWrapper><PublishDashboard published={true} /></SuspenseWrapper>
            },
            {
                path: "publishData/fetchDashboard/:id/report/:reportID",
                element: <SuspenseWrapper><PolicyReport published={true} /></SuspenseWrapper>
            },
            {
                path: "publishData/fetchDashboard/:id/report/:reportID/:analysisID/:detailedBiasUUID",
                element: <SuspenseWrapper><PolicyDetails published={true} /></SuspenseWrapper>
            }
        ]
    },
    {
        path: "/login",
        element: <SuspenseWrapper><LoginPageContainer /></SuspenseWrapper>
    },
    {
        path: "*",
        element: <SuspenseWrapper><Page404 /></SuspenseWrapper>
    }
]);

export default router;