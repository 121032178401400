const { io } = require("socket.io-client");

export class SocketClient {
    private static socketInstance: SocketClient | null = null;
    private socket: any = null;
    private socketConfig = {
        "reconnectionDelay": 1000,
        "reconnectionDelayMax": 5000,
        "timeout": 100000,
        "rejectUnauthorized": false
    }

    public createClient(): void {
        this.socket = io('/', {
            transports: ['websocket', 'polling'],
            path: "/socket/socket.io",
            rejectUnauthorized: this.socketConfig?.rejectUnauthorized,
            reconnectionDelay: this.socketConfig?.reconnectionDelay,
            reconnectionDelayMax: this.socketConfig?.reconnectionDelayMax,
            timeout: this.socketConfig?.timeout,
            autoConnect: false,
            auth: { jwt: sessionStorage.getItem('accessToken') },
        });
        this.socket.on("connect_error", (err: any) => {
            console.log(err.message);
        });
        this.socket.on("connect", () => {
            console.log(`Socket connection established to Server!`);
        });
        this.socket.on("disconnect", (reason: any) => {
            if (reason === 'io server disconnect') {
                console.log(`Socket disconnected from server! Client will not reconnect! Please login again`);
            }
            else {
                console.log(`Socket disconnected from server! Reason: ${reason}`);
            }
            try {
                this.attemptToReconnect();
            }
            catch (err) {
                console.log(`Socket is disconnected from the server! It cannot reconnect : ${err}. Please refresh the page or login again to continue.`)
            }
        });
        this.socket.on("authenticate", async (cb: Function) => {
            try {
                const payload: any = {
                    refreshToken: sessionStorage.getItem('refreshToken'),
                }
                const token: string = sessionStorage.getItem('accessToken') || '';
                const accessTokenRes: any = await fetch('/surveyAdmin/api/v1.0/userauth/fetchRefreshedAccessToken', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': token,
                    },
                })
                if (accessTokenRes.err) throw accessTokenRes.err;
                const accessToken = accessTokenRes?.data?.data?.accessToken;
                this.socket.auth = {
                    jwt: accessToken
                };
                if (accessToken) sessionStorage.setItem('accessToken', accessToken)
                cb(accessToken);
            }
            catch (err) {
                console.log(err);
            }
        });
        this.socket.io.on("reconnect_attempt", async (attempt: any) => {
            this.socket.auth = {
                jwt: sessionStorage.getItem('accessToken')
            };
        });
        this.socket.io.on("reconnect", (attempt: any) => {
            console.log(`Socket connection established to Server after attmept: ${attempt}`);
        });
    }

    attemptToReconnect(): void {
        var x = setInterval(() => {
            console.log('Attempting to reconnect.....');
            this.connect();
            if (this.isConnected()) clearInterval(x)
        }, 10000);
    }

    disconnect(): void {
        console.log(`Disconnecting from Socket Server!`);
        this.socket.disconnect();
    }

    connect(token?: string): void {
        this.socket.auth = {
            jwt: sessionStorage.getItem('accessToken')
        };
        this.socket.connect();
    }

    on(eventName: string, eventHandler: Function): void {
        this.socket.on(eventName, eventHandler);
    }

    isConnected(): boolean {
        return this.socket.connected;
    }

    public static getSocketInstance(): SocketClient {
        if (!SocketClient.socketInstance) {
            SocketClient.socketInstance = new SocketClient();
        }
        return SocketClient.socketInstance;
    }

    public getSocket(): any {
        return this.socket;
    }
}