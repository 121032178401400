import { Action, CombinedState, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from 'redux-persist'
import { combineReducers } from "redux"
import { apiSlice } from "./apiSlice"
import { rtkQueryLogger } from "./NotifyMiddleware"
import sessionStorage from 'redux-persist/lib/storage/session';
import authReducer from "../loginPage/store/slice"
import homeReducer from "../homePage/store/slice"
import dashboardReducer from "../Dashboard/store/Slice"
import analysisReducer from "../analysis/store/slice"
import widgetReducer from "../analysisResult/store/slice"
import globalReducer from "../global/store/slice"
import csrfReducer from "../store/csrfSlice"

//white list the reducers to retain value on refresh
const rootPersistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['auth'] //add name of reducer here
};

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    global: globalReducer,
    home: homeReducer,
    dashboard: dashboardReducer,
    analysis: analysisReducer,
    widget: widgetReducer,
    csrf: csrfReducer
});

const combinedReducer = (
    state: CombinedState<any> | undefined,
    action: Action
) => {
    // Reset all state to initial state upon logout
    if (action.type === 'user/logout') {
        state = undefined;
    }
    return rootReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, combinedReducer);
// const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware().concat(apiSlice.middleware).concat(rtkQueryLogger),
    devTools: true
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
