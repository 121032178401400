import { layoutBreakpoints, policyLayout, DIATlayout, lowTouchLayout, highTouchLayout, ONALayout, layoutCols, DIATlayouts, policyLayouts, highTouchLayouts, lowTouchLayouts, ONALayouts, OverallCulturalLayouts, OverallDIATlayouts, TALayouts, OverallDIATlayout, OverallCultural } from "./Layout";

export const url = {
    project: {
        fetchAll: "SmartCore/api/v1.0/projects"
    },
    dashboard: {
        fetchAll: "SmartCore/api/v1.0/dashboard/fetchAllDashboards",
        create: "SmartCore/api/v1.0/dashboard",
        delete: "SmartCore/api/v1.0/dashboard",
        update: "SmartCore/api/v1.0/dashboard",
        updateLayout: "SmartCore/api/v1.0/dashboard/updateLayout",
        fetchAnalysis: "SmartCore/api/v1.0/dashboard/fetchAnalysis",
        validateDashboardName: "SmartCore/api/v1.0/dashboard/validateDashboardName",
        createOverallDashboardWidget: "analysisCore/overallDashboard",
        checkDashboardExists: "SmartCore/api/v1.0/dashboard/checkDashboardExists"
    },
    analysis: {
        fetchAll: "SmartCore/api/v1.0/analysis/fetchAllAnalysis",
        create: "SmartCore/api/v1.0/analysis",
        delete: "SmartCore/api/v1.0/analysis"
    },
    wigets: {
        fetchList: "SmartCore/api/v1.0/widgets",
        fetchData: "SmartCore/api/v1.0/widgets/fetchWidgetData",
        update: "SmartCore/api/v1.0/widgets",
        modifyFilter: "analysisCore/modifyAnalysis",
        softDelete: "SmartCore/api/v1.0/widgets/softDeleteWidgets",
        presign: "SmartCore/api/v1.0/presign",
        analysisAlreadyExists: "SmartCore/api/v1.0/dashboard/analysisAlreadyExists",
        modifyDashboard: "analysisCore/modifyDashboard",
        updateWidgetTitle: "SmartCore/api/v1.0/widgets/editTitle"
    },
    publish: {
        publishDashboard: "SmartCore/api/v1.0/publishData/fetchDashboard",
        fetchWidgets: "SmartCore/api/v1.0/publishData/fetchWidgets"
    }
};

export const tags = {
    dashboard: {
        fetch: "fetchDashboard"
    },
    analysis: {
        fetch: "fetchAnalysis"
    }, layout: {
        fetch: "layout"
    }
};

export const biasLevel = {
    color: {
        NoBias: "#90C210",
        low: "#FCC509",
        medium: "#FE8541",
        high: "#D93550"
    }

};

export const reverseBiasLevel = {
    color: {
        NoBias: "#D93550",
        low: "#FE8541",
        medium: "#FCC509",
        high: "#90C210"
    }

};

export const wordClodColor = ["#1B72BF", "#3D90D9", "#26890D", "#00638D", "#2A4581", "#ED8B00", "#0D8390", "#000000"];


export const filterOpts = [
    { label: "All", value: "All" },
    { label: "Age", value: "Age" },
    { label: "Gender", value: "Gender" },
    { label: "Education", value: "Education" },
    { label: "Relation", value: "Relation" }
];


export const filterDemography = [
    { label: "Age", value: "Age" },
    { label: "Gender", value: "Gender" },
    { label: "Location", value: "Location" },
    { label: "JobLevel", value: "JobLevel" }
];

export const policyFilter = [
    { label: "Company policies", value: "Company policies" },
    { label: "Internal communications", value: "Internal communications" },
    { label: "Job postings", value: "Job postings" }
];

export const banner = {
    diat: {
        title: "Individual Intrinsic Bias",
        summry: "An Implicit Association Test (IAT) is a psychological test whose objective is to reveal unconscious attitudes, automatic preferences, and hidden biases by measuring the time that takes an individual to classify concepts into two categories.",
    },
    policy: {
        title: "Communications & Company Policies",
        summry: "This report highlights the unconscious bias present in the form of biased language or tone etc. across policies, communications and job ads of an organisation.",
    },
    internalCultural: {
        title: "Culture Analytics",
        summry: "Customized survey analysis and internal survey (exit survey, pulse survey) analysis across pillars.",
    },
    externalCulture: {
        title: "Culture Analytics",
        summry: "Analysis of social media comments for market perception across pillars.",
    },
    overallCulture: {
        title: "Overall Culture Analytics",
        summry: "Culture Analysis assesses employees perception by actively / passively hearing and understanding employees experience across key Cultural pillars and their Core drivers.",
    },
    ona: {
        title: "Internal Collaboration Bias",
        summry: "An Internal Collaboration Bias aims to explore biases that may hinder teamwork, collaboration, and decision-making within an organisation. It helps to identify biases that impede information sharing or create barriers among internal teams and departments, promoting effective collaboration and a more cohesive workplace.",
    },
    overallpotential: {
        title: "Potential Unconscious Bias - Overall",
        summry: "Potential Unconscious Bias Detection helps organisations decipher the existence and intensity of unconscious bias across different bias type and cohorts.",
    },
    talent: {
        title: "Talent Process",
        summry: "Biases in talent process can originate in the form of unequal representation in leadership positions, pay inequity, favoritism in performance ratings, biased recruitment and selection and unequal opportunities for training and development.",
    }
};

export const legend = {
    DIAT: [
        { label: "No bias", color: "#90C210" },
        { label: "Low bias", color: "#FCC509" },
        { label: "Medium bias", color: "#FE8541" },
        { label: "High bias", color: "#D93550" }
    ],
    TA: [
        { label: "No bias", color: "#90C210" },
        { label: "Low bias", color: "#FCC509" },
        { label: "Medium bias", color: "#FE8541" },
        { label: "High bias", color: "#D93550" }
    ],
    CohortTA: [
        { label: "Score Decrease", color: "#90C210" },
        { label: "Score unchanged", color: "#FCC509" },
        { label: "Score increase", color: "#D93550" }
    ],
    ShortedGraph: [
        { label: "Finance", color: "#BAE8E8" },
        { label: "Consultancy", color: "#95ADBE" },
        { label: "Audit", color: "#574F7D" },
        { label: "HR", color: "#1A1B4B" }
    ],
    TalentTA: [
        { label: "Performance", color: "#00B3FF" },
        { label: "Compensation", color: "#853991" },
        { label: "Promotion", color: "#00DFBD" },
        { label: "Learning & Development", color: "#F7357A" },
        { label: "Recruitment", color: "#007966" }
    ],
    BiasByManifestation: [
        { label: "IAT", color: "#00B3FF" },
        { label: "ONA", color: "#853991" },
        { label: "Policy", color: "#00DFBD" },
        { label: "Talent", color: "#F7357A" }
    ],
    colors: ["#A0DCFF", "#95ADBE", "#574F7D", "#1A1B4B"]
};

export const pieColorslist = ['#90c210', '#a0c30f', '#a0c30f', '#b6c30e', '#bfc30d', '#cfc40c', '#dfc50b', '#ecc50a', '#f6c509', '#fcc509', '#fcbf0e', '#fcb913', '#fcb318', '#fcad1d', '#fda624', '#fda029', '#fe9930', '#fe9137', '#fd8b3b', '#fc8042', '#f57245', '#ef6647', '#ea5949', '#e44d4b', '#dd3f4e', '#d93550'];
export const revPiColorList = ['#d93550', '#dd3f4e', '#e44d4b', '#ea5949', '#ef6647', '#f57245', '#fc8042', '#fd8b3b', '#fe9137', '#fe9930', '#fda029', '#fda624', '#fcad1d', '#fcb318', '#fcb913', '#fcbf0e', '#fcc509', '#f6c509', '#ecc50a', '#dfc50b', '#cfc40c', '#bfc30d', '#b6c30e', '#a0c30f', '#a0c30f', '#90c210'];

export const color = {
    base: "#26890D",
    white: "#FFFFFF",
    black: "#000000",
    teal: "#007680",
    blue: "#0D2689",
    orange: "#ED8B00",
    yellowGreen: "#C4D600",
    header: "#53565a",
    border: "#D0D0CE",
    borderGray: "#97999B",
    disabled: "#777777",
    baseDisabled: "#A8D09E",
    error: "#DA291C",
    warnning: "#FFCD00",
    lowRisk: "#43B02A",
    mediumRisk: "#FFCD00",
    highRisk: "#DA291C",
    coolGray10: " #63666A"
};

export {
    layoutBreakpoints,
    layoutCols,
    DIATlayouts,
    policyLayouts,
    highTouchLayouts,
    lowTouchLayouts,
    ONALayouts,
    OverallCulturalLayouts,
    OverallDIATlayouts,
    TALayouts,
    policyLayout,
    DIATlayout,
    lowTouchLayout,
    highTouchLayout,
    ONALayout,
    OverallDIATlayout,
    OverallCultural
};
