import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface DashboardState {
    selectedKPIs: string,
    selectedBias: string,
    widgetWithKPIs: FilterPayload[],
    widgetWithBias: FilterPayload[],
}

const initialState: DashboardState = {
    selectedKPIs: "",
    selectedBias: "",
    widgetWithKPIs: [],
    widgetWithBias: [],

}

export const dashboardSlice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        selectKPIs: (state, action: PayloadAction<{ value: string }>) => {
            state.selectedKPIs = action.payload.value
        },
        selectBias: (state, action: PayloadAction<{ value: string }>) => {
            state.selectedBias = action.payload.value
        },
        addToKPI: (state, action: PayloadAction<FilterPayload>) => {
            if (!state.widgetWithKPIs.find((w) => w.widgetID === action.payload.widgetID)) {
                state.widgetWithKPIs.push(action.payload)
            }
        },
        addToBias: (state, action: PayloadAction<FilterPayload>) => {

            if (!state.widgetWithBias.find((w) => w.widgetID === action.payload.widgetID)) {
                state.widgetWithBias.push(action.payload)
            }
        },
        removeBias: (state, action: PayloadAction<{ widgetID: string }>) => {
            let filteredList = state.widgetWithBias.filter((w) => w.widgetID !== action.payload.widgetID)
            state.widgetWithBias = filteredList

        },
        removeKpi: (state, action: PayloadAction<{ widgetID: string }>) => {
            let filteredList = state.widgetWithKPIs.filter((w) => w.widgetID !== action.payload.widgetID)
            state.widgetWithKPIs = filteredList
        },

    },
})

export const { selectBias, selectKPIs, addToBias, addToKPI, removeBias, removeKpi } = dashboardSlice.actions

export default dashboardSlice.reducer