import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';



export const fetchCSRFToken = createAsyncThunk(
    'csrf/fetchCSRFToken',
    async () => {
        const token = sessionStorage.getItem('accessToken');
        const response: any = await axios.get('/SmartCore/api/v1.0/csrfToken', {
            headers: {
                "authorization": token
            }
        });
        const { csrfToken } = response;
        return csrfToken;
    }
);

const initState: {
    token: string | null,
    status: string | null,
    error: string | null | undefined

} = {
    token: null,
    status: 'idle',
    error: null
}

const csrfSlice = createSlice({
    name: 'csrf',
    initialState: initState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCSRFToken.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCSRFToken.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload;
            })
            .addCase(fetchCSRFToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default csrfSlice.reducer;
