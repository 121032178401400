import { createSlice } from "@reduxjs/toolkit";

export interface authState {
    user: Record<string, any> | null,
    token: string | null,
    publishurl: string | null,
    selectedOrg: string | null,
    sessionID: string | null,
    logoutNow: boolean,
    sessionPopup: boolean,
    sessionExistPopup: boolean,
    pageLoading: boolean,
}

const initialState = {
    user: null,
    token: null,
    selectedOrg: null,
    sessionID: null,
    publishurl: null,
    logoutNow: false,
    sessionPopup: false,
    sessionExistPopup: false,
    pageLoading: false,
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setpublishurl: (state, action) => {
            const publishurl = action.payload
            state.publishurl = publishurl
        },
        setCredentials: (state, action) => {
            const user = action.payload
            state.user = user
            state.logoutNow = false
        },
        setselectedorg: (state, action) => {
            const selectedOrg = action.payload.val.value
            state.selectedOrg = selectedOrg
        },
        logOut: (state, action) => {
            sessionStorage.removeItem("accessToken")
            sessionStorage.removeItem("refreshToken")
            sessionStorage.removeItem("idToken")
            sessionStorage.removeItem("tabId")
            sessionStorage.removeItem("tabId")
            state.user = null
            state.token = null
            state.selectedOrg = null
            state.logoutNow = false
            state.publishurl = null
            // sessionStorage.clear();
        },
        triggerLogout: (state, action) => {
            sessionStorage.removeItem("accessToken")
            sessionStorage.removeItem("refreshToken")
            sessionStorage.removeItem("idToken")
            sessionStorage.removeItem("tabId")
            sessionStorage.removeItem("tabId")
            state.logoutNow = true
            // sessionStorage.clear();
        },
        setSession: (state, action) => {
            const sessionID = action.payload
            state.sessionID = sessionID
            state.logoutNow = false
        },
        setSessionPopup: (state, action) => {
            const sessionPopup = action.payload
            state.sessionPopup = sessionPopup
        },
        setSessionExistPopup: (state, action) => {
            const sessionExistPopup = action.payload
            state.sessionExistPopup = sessionExistPopup
        },
        setPageLoading: (state, action) => {
            const pageLoading = action.payload
            state.pageLoading = pageLoading
        }
    }
})

export const { setCredentials, logOut, setselectedorg, setSession, triggerLogout, setpublishurl, setSessionPopup, setSessionExistPopup, setPageLoading } = authSlice.actions;

export default authSlice.reducer;