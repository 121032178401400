import { isRejectedWithValue, isRejected, isFulfilled } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { tostMsg } from '../global/store/slice'; // Import your error action
import { triggerLogout } from '../loginPage/store/slice';

export const rtkQueryLogger: Middleware =
    (api: MiddlewareAPI) => (next) => async (action) => {
        // if (action?.payload?.data?.message === "Session Expired") {
        //     api.dispatch(triggerLogout({}));
        // }
        if (isRejectedWithValue(action)) {
            api.dispatch(tostMsg({
                type: "ERROR", msg: (action?.payload?.data?.message || action?.payload?.data?.msg || action?.payload?.error)?.split("Error: ")[1]
            }));
        } else if (isFulfilled(action)) {
            const successMessage = action?.payload?.message || action?.payload?.msg;

            if (successMessage) {
                api.dispatch(tostMsg({ type: "SUCCESS", msg: successMessage }));
            }
        }
        return next(action);
    };
